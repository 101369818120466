import image from './salomon_lee.jpeg';

import './App.css';

function App() {
  return (
    <div className="App">
      <p class="fixed left-0 top-0 flex w-full justify-center border-b border-gray-300 bg-gradient-to-b from-zinc-200 pb-6 pt-8 backdrop-blur-2xl dark:border-neutral-800 dark:bg-zinc-800/30 dark:from-inherit lg:static lg:w-auto  lg:rounded-xl lg:border lg:bg-gray-200 lg:p-4 lg:dark:bg-zinc-800/30">Check my repos to get to know me a lil'bit on &nbsp;<code class="font-mono font-bold">https://github.com/lsmon</code></p>
      <header className="App-header">
        {/* <img src="{logo}" className="App-logo" alt="logo" /> */}
        <img src={image} className='App-logo App-' alt='Salomon Lee' />
        <div class="mb-32 grid text-center lg:mb-0 lg:w-full lg:max-w-5xl lg:grid-cols-1 lg:text-left">
        <div class=" py-4 px-5 rounded-lg mb-4">
                <div class="max-w-full mx-auto p-4 pt-6 pb-8 rounded-xl shadow-md">
                    <h1 class="text-3xl font-bold mb-2">Salomon Lee</h1>
                    <p class="text-lg text-gray-400 mb-4">Senior Software Engineer, Architect &amp; CTO</p>
                    <div class="grid grid-cols-1 gap-4 mb-4">
                        <div>
                            <h2 class="text-2xl font-bold mb-2">Summary</h2>
                            <p class="text-justify text-base text-gray-200 mb-4">Highly experienced software architect and technology leader with 15+ years of expertise in cloud-native software development, API deployment, and cutting-edge technologies. Proven track record of driving innovation and delivering meaningful business impact.</p>
                        </div>
                    </div>
                    </div></div></div>
      </header>
      <body className='App-header'>
      <div class="mb-32 grid text-center lg:mb-0 lg:w-full lg:max-w-5xl lg:grid-cols-1 lg:text-left">
            <div class="py-4 px-5 rounded-lg mb-4">
                <div class="max-w-full mx-auto p-4 pt-6 pb-8 rounded-xl shadow-md">
                    <div class="grid grid-cols-1 gap-4 mb-4">
                        <div>
                            <h2 class="text-2xl font-bold mb-2">Experience</h2>
                            <div class="gap-8 mb-4">
                                <h3 class="text-lg font-bold mb-2">Chief Technology Officer, Competestv, Belmont, CA</h3>
                                <p class="text-sm text-gray-400 mb-4">September 2023 - Present</p>
                                <ul class="list-none m-0 p-0">
                                    <li class="text-sm text-gray-200 text-justify mb-2">Spearheaded the development of a high-performing iOS video player, resulting in a 35% boost in user engagement</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Architected a microservices-based platform on Google Cloud Platform, leveraging Kubernetes and Docker for scalability and reliability</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Drove a 25% increase in streaming performance through advanced video transcoding using CUDA and C++</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Defined cloud infrastructure and content delivery strategies on AWS, resulting in a 20% reduction in cloud costs</li>
                                </ul>
                            </div>
                            <div class="gap-8 mb-4">
                                <h3 class="text-lg font-bold mb-2">Chief Technology Officer, Alcacruz, Belmont, CA</h3>
                                <p class="text-sm text-gray-400 mb-4">November 2022 - Present</p>
                                <ul class="list-none m-0 p-0">
                                    <li class="text-sm text-gray-200 text-justify mb-2">Established the strategic technology vision and roadmap, aligning IT initiatives with core business objectives</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Oversaw the development and deployment of cloud infrastructure, mobile applications, and web interfaces across multiple cloud platforms</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Pioneered the implementation of DevSecOps practices, enhancing the reliability and security of Alcacruz&#x27;s SaaS offerings</li>
                                </ul>
                            </div>
                            <div class="gap-8 mb-4">
                                <h3 class="text-lg font-bold mb-2">Cloud Software Architect, Alcacruz, San Mateo, CA</h3>
                                <p class="text-sm text-gray-400 mb-4">April 2019 - November 2022</p>
                                <ul class="list-none m-0 p-0">
                                    <li class="text-sm text-gray-200 text-justify mb-2">Architected proprietary video transcoding technology for smooth streaming, leveraging CUDA and C++ for optimal performance</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Translated business requirements into actionable development milestones using Agile methodologies and tools</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Orchestrated cloud DevOps, big data, and RESTful API development using Docker, Kubernetes, GCP, and AWS</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Delivered an innovative 8K VR 360 video streaming solution in collaboration with Korea Telecom, expanding the company&#x27;s global reach</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Managed the live-streaming of the Michigan High School Football State Championship finals with Fox Sports, demonstrating the platform&#x27;s scalability and reliability</li>
                                </ul>
                            </div>
                            <div class="gap-8 mb-4">
                            <h3 class="text-lg font-bold mb-2">Software Engineer, Alcacruz, San Mateo, CA</h3>
                            <p class="text-sm text-gray-400 mb-4">April 2015 - April 2019</p>
                            <ul class="list-none m-0 p-0">
                            <li class="text-sm text-gray-200 text-justify mb-2">Implemented client authentication using OAuth and RSA encryption</li>
                            <li class="text-sm text-gray-200 text-justify mb-2">Integrated Elasticsearch and Apache Cassandra for advanced data management and analytics</li>
                            <li class="text-sm text-gray-200 text-justify mb-2">Conducted research and development in machine learning, deep learning, and neural networks</li>
</ul>
            </div>
            <div class="gap-8 mb-4">
            <h3 class="text-lg font-bold mb-2">Software Developer, Neo Romax Inc., San Francisco</h3>
            <p class="text-sm text-gray-400 mb-4">April 2012 - February 2015</p>
            <ul class="list-none m-0 p-0">
            <li class="text-sm text-gray-200 text-justify mb-2">Specialized in web service and API development using Java, Jetty, JSON, and DataStax Cassandra</li>
            <li class="text-sm text-gray-200 text-justify mb-2">Proficient in web UI development with ASP.NET, C#, jQuery, and JavaScript</li>
            <li class="text-sm text-gray-200 text-justify mb-2">Migrated a data warehouse from RDBMS (SQL Server) to NoSQL (Cassandra) in just 2 months</li>
            </ul>
            </div>
            <div class="gap-8 mb-4">
            <h3 class="text-lg font-bold mb-2">System analyst / Interpreter, Samsung SDS, San Jose, Costa Rica</h3>
            <p class="text-sm text-gray-400 mb-4">July 2009 - December 2010</p>
            <ul class="list-none m-0 p-0">
            <li class="text-sm text-gray-200 text-justify mb-2">Korean - Spanish Interpreter and System Analyst for Costa Rica's government e-procurement system Mer-link (currently known: sigcop)</li>
            <li class="text-sm text-gray-200 text-justify mb-2">Analysis of Legal government procurement procedures</li>
            <li class="text-sm text-gray-200 text-justify mb-2">Validating procedures translation to technical requirements</li>
            </ul>
            </div>
            <div class="gap-8 mb-4">
            <h3 class="text-lg font-bold mb-2">Software Engineer / NCanvas, Seoul, South Korea</h3>
            <p class="text-sm text-gray-400 mb-4">June 2007 - May 2009</p>
            <ul class="list-none m-0 p-0">
            <li class="text-sm text-gray-200 text-justify mb-2">Unicast Web Service Architecure design</li>
            <li class="text-sm text-gray-200 text-justify mb-2">Backend engineer and developer (J2EE/JMS, STOMP protocol)</li>
            <li class="text-sm text-gray-200 text-justify mb-2">Client Prototyping, Action Script</li>
            </ul>
            </div>
                        </div>
                        
                        <div class="grid grid-cols-1 gap-4 mb-4">
                            <h2 class="text-lg font-bold mb-2">Awards</h2>
                            <div>
                                <h3 class="text-lg font-bold mb-2">Systems and method for GPU-based virtual reality video streaming server</h3>
                                <p class="text-sm text-gray-400 mb-4">
                                    <a href="https://patents.google.com/patent/US20190253693A1" class="text-orange-200 hover:text-orange-500">Patent: US20190253693A1</a>
                                    , 2021 granted
                                </p>
                            </div>
                            <div>
                                <h3 class="text-lg font-bold mb-2">Digital media system</h3>
                                <p class="text-sm text-gray-400 mb-4">
                                    <a href="https://patents.google.com/patent/US11589110B2/" class="text-orange-200 hover:text-orange-500">Patent: US11589110B2</a>
                                    , 2023 granted
                                </p>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 gap-4 mb-4">
                            <h2 class="text-lg font-bold mb-2">Education</h2>
                            <div>
                                <h3 class="text-lg font-bold mb-2">Seoul National University, Seoul, South Korea</h3>
                                <p class="text-sm text-gray-400 mb-4">
                                    Bachelor of Science, 
                                    <a href="https://cse.snu.ac.kr/en" class="text-orange-400 hover:text-orange-500">Computer Science and Engineering</a>
                                    , 2008
                                </p>
                            </div>
                            <div>
                                <h3 class="text-lg font-bold mb-2">ENET No. 9 Ing. Luis A. Huergo, Buenos Aires, Argentina</h3>
                                <p class="text-sm text-gray-400 mb-4">
                                    Técnico Químico, 
                                    <a href="https://www.et9huergo.edu.ar" class="text-orange-400 hover:text-orange-500">Especialidad Química</a>
                                    , 1999
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="w-full">
                                <h2 class="text-lg font-bold mb-2">Skills</h2>
                                <ul class="list-none m-0 p-0">
                                    <li class="text-sm text-gray-200 text-justify mb-2">Cloud Platforms: AWS, Google Cloud, Azure, Digital Ocean</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">DevOps &amp; Containerization: Docker, Kubernetes, Terraform, CircleCI, Jenkins</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Programming Languages: Java, C++, Objective-C, Swift</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Big Data &amp; Analytics: Apache Cassandra, Elasticsearch, Apache Kafka, Apache Spark, SQL Servers</li>
                                    <li class="text-sm text-gray-200 text-justify mb-2">Project Management: Jira, Confluence, Trello, Notion, SocraticWorks</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="w-full">
                                <h2 class="text-lg font-bold mb-2">Contact Info</h2>
                                <ul class="list-none m-0 p-0">
                                    <li class="text-sm text-gray-200 text-justify mb-2">
                                        LinkedIn: 
                                        <a href="https://www.linkedin.com/in/salomon-lee" class="text-orange-400 hover:text-orange-500">LinkedIn Profile</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </body>
    </div>
  );
}

export default App;
